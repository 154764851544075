import type { DefaultPrivacyLevel } from '@datadog/browser-rum'

const bigCommerceStoreHash = process.env.NEXT_PUBLIC_BC_STORE_HASH ?? ''

const domain = process.env.NEXT_PUBLIC_DOMAIN ?? ''

/**
 * RUNTIME CONFIG WRAPPER
 * next.config.js wrapper to reduce verbosity in obtain a config value at run time
 */
export type publicConfig = typeof publicConfig
const NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_NEW_CUSTOMER =
  process.env.NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_NEW_CUSTOMER

const NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_EXISTING_CUSTOMER =
  process.env.NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_EXISTING_CUSTOMER

if (!NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_NEW_CUSTOMER)
  throw new Error('Must set NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_NEW_CUSTOMER')

if (!NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_EXISTING_CUSTOMER)
  throw new Error('Must set NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_EXISTING_CUSTOMER')

const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API ?? ''

if (!googleMapsApiKey) {
  throw new Error('Must set NEXT_PUBLIC_GOOGLE_MAPS_API')
}

const trackingUrlBase = process.env.NEXT_PUBLIC_TRACKING_URL_BASE

if (!trackingUrlBase) {
  throw new Error('Must set NEXT_PUBLIC_TRACKING_URL_BASE')
}

const chironUrl = process.env.NEXT_PUBLIC_CHIRON_BASE_URL

// TODO : figure it out why not working well
const avsFormFieldName = process.env.AVS_FORM_FIELD_NAME || 'Avs Deliverability'

export const publicConfig = {
  chironUrl,
  searchspringSiteId: process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID ?? '',
  nostoAccountId: `bigcommerce-${bigCommerceStoreHash}`,
  loggingEnabled: process.env.NEXT_PUBLIC_LOGGING_ENABLED === 'true',
  builderIOApiKey: process.env.NEXT_PUBLIC_BUILDER_IO_API_KEY ?? '',
  kustomerAssistantIdReturns: process.env.NEXT_PUBLIC_KUSTOMER_ASSISTANT_ID_RETURNS ?? '',
  autoshipEngineApiUrl: process.env.NEXT_PUBLIC_AUTOSHIP_ENGINE_API_URL ?? '',
  legacyCarewellApiUrl: process.env.NEXT_PUBLIC_LEGACY_CAREWELL_API_URL ?? '',
  bigCommerceGraphQLApiUrl: `https://store-${bigCommerceStoreHash}.mybigcommerce.com/graphql`,
  yotpoApiKey: process.env.NEXT_PUBLIC_YOTPO_API_KEY ?? '',
  bigCommerceStoreHash: process.env.NEXT_PUBLIC_BC_STORE_HASH ?? '',
  domain,
  trackingUrl: trackingUrlBase,
  // TODO: Prob better to load from e.g. cw-api?
  brandIdentifiers: process.env.NEXT_PUBLIC_BRAND_IDENTIFIERS
    ? JSON.parse(process.env.NEXT_PUBLIC_BRAND_IDENTIFIERS)
    : {},
  categoryIdentifiers: process.env.NEXT_PUBLIC_CATEGORY_IDS
    ? JSON.parse(process.env.NEXT_PUBLIC_CATEGORY_IDS)
    : {},
  privateLabelProductIdentifiers: process.env.NEXT_PUBLIC_PRIVATE_LABEL_PRODUCT_IDS
    ? JSON.parse(process.env.NEXT_PUBLIC_PRIVATE_LABEL_PRODUCT_IDS)
    : {},
  absoluteUrl: process.env.ALLOWED_CORS_ORIGIN,
  dataDogRum: {
    applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID ?? '',
    clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN ?? '',
    site: process.env.NEXT_PUBLIC_DD_RUM_SITE ?? '',
    service: process.env.NEXT_PUBLIC_DD_RUM_SERVICE ?? '',
    sessionSampleRate: parseInt(process.env.NEXT_PUBLIC_DD_RUM_SESSION_SAMPLE_RATE ?? '100'),
    sessionReplaySampleRate: parseInt(
      process.env.NEXT_PUBLIC_DD_RUM_SESSION_REPLAY_SAMPLE_RATE ?? '20'
    ),
    trackUserInteractions: process.env.NEXT_PUBLIC_DD_RUM_TRACK_USER_INTERACTIONS === 'true',
    trackResources: process.env.NEXT_PUBLIC_DD_RUM_TRACK_RESOURCES === 'true',
    trackLongTasks: process.env.NEXT_PUBLIC_DD_RUM_TRACK_LONG_TASKS === 'true',
    defaultPrivacyLevel: (process.env.NEXT_PUBLIC_DD_RUM_DEFAULT_PRIVACY_LEVEL ??
      'mask-user-input') as DefaultPrivacyLevel,
    htmlPrivacyLevel: (process.env.NEXT_PUBLIC_DD_RUM_HTML_PRIVACY_LEVEL ??
      'mask') as DefaultPrivacyLevel,
    environment: process.env.NEXT_PUBLIC_DD_RUM_ENV ?? process.env.NODE_ENV,
  },
  autoship: {
    promotions: {
      newCustomer: parseInt(NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_NEW_CUSTOMER, 10),
      existingCustomer: parseInt(NEXT_PUBLIC_PROMOTION_ID_AUTOSHIP_EXISTING_CUSTOMER, 10),
    },
  },
  googleMapsApiKey,
  release: {
    deployRef: process.env.NEXT_PUBLIC_DEPLOY_INPUT_REF ?? '',
    githubRef: process.env.NEXT_PUBLIC_GITHUB_REF ?? '',
    npmPackageVersion: process.env.NEXT_PUBLIC_NPM_PACKAGE_VERSION ?? '',
    gitSha: process.env.NEXT_PUBLIC_GITHUB_SHA ?? '',
  },
  avsFormFieldName
}

export const featureFlags = {
  displayPromoAlert: process.env.NEXT_PUBLIC_FF_DISPLAY_PROMO_ALERT === 'true',
  displayShippingDelayAlert: process.env.NEXT_PUBLIC_FF_DISPLAY_SHIPPING_DELAY_ALERT === 'true',
  displayPhoneOutageAlert: process.env.NEXT_PUBLIC_FF_DISPLAY_PHONE_OUTAGE_ALERT === 'true',
}

// TODO: Remove before deploy
if (typeof window !== 'undefined') {
  ;(window as any).publicConfig = publicConfig
  ;(window as any).featureFlags = featureFlags
}
